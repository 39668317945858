import * as React from "react";
import styles from "./Phone.module.scss";
import LazyProductImage from "../../../components/lazy-product-image/lazy-product-image";
import LazyProductVideo from "../../../components/lazy-product-image/lazy-product-video";
import { useTranslation } from "next-i18next";
import { getLang } from "../../../utils/lang";
import { getUserAgent } from "../../../utils/agent";
import { IUserAgent } from "../../../types/ua";
function PhoneNewComponent(props: { store: string }) {
  const { t, i18n } = useTranslation("phone");
  const [isMounted, setMounted] = React.useState(false);
  const [userAgent, setUserAgent] = React.useState<IUserAgent>(
    {} as IUserAgent
  );
  React.useEffect(() => {
    const ua = getUserAgent();
    setUserAgent(ua);
    setMounted(true);
  }, []);

  return (
    <div className={`${styles.phone} container align-items-center`}>
      <div className="row">
        <div className="col-1 col-sm-0 col-md-1" />
        <div className={`col-5 col-sm-12 col-md-5 ${styles.content}`}>
          <h1>
            Your daily drop of <i>cool</i>
          </h1>
          <h2>
            {t("slogan", { lng: props.store === "us" ? "en" : undefined })}
          </h2>
          {isMounted ? (
            <div className={styles.stores}>
              {(userAgent.isMobile && !userAgent.isAndroid) ||
              !userAgent.isMobile ? (
                <>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.big}
                    title="Apple Store"
                    href="https://itunes.apple.com/fr/app/choose-concept-store/id985799982?mt=8&ct=website"
                  >
                    <LazyProductImage
                      width="182"
                      height="54"
                      alt="Apple Store"
                      src={`https://public.choose.app/website/new/images/btn-big-app-store-${getLang(
                        i18n
                      )}.svg`}
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.small}
                    title="Apple Store"
                    href="https://itunes.apple.com/fr/app/choose-concept-store/id985799982?mt=8&ct=website"
                  >
                    <LazyProductImage
                      width="134"
                      height="40"
                      alt="Apple Store"
                      src={`https://public.choose.app/website/new/images/btn-small-app-store-${getLang(
                        i18n
                      )}.svg`}
                    />
                  </a>
                </>
              ) : null}
              {(userAgent.isMobile && userAgent.isAndroid) ||
              !userAgent.isMobile ? (
                <>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.appchoose.choose.android&hl=fr&gl=FR"
                    rel="noopener noreferrer"
                    className={styles.big}
                    target="_blank"
                  >
                    <LazyProductImage
                      width="182"
                      height="54"
                      alt="Play Store"
                      src={`https://public.choose.app/website/new/images/btn-big-play-store-${getLang(
                        i18n
                      )}.svg`}
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.appchoose.choose.android&hl=fr&gl=FR"
                    rel="noopener noreferrer"
                    className={styles.small}
                    target="_blank"
                  >
                    <LazyProductImage
                      height="41"
                      width="136"
                      alt="Play Store"
                      src={`https://public.choose.app/website/new/images/btn-small-play-store-${getLang(
                        i18n
                      )}.svg`}
                    />
                  </a>
                </>
              ) : null}
            </div>
          ) : null}
          <div className={styles.rate}>
            <div className={`${styles.star}`}>
              <LazyProductImage
                height="32"
                width="168"
                alt="Rate"
                src="https://public.choose.app/website/2023/Stars.png"
                srcSet={`https://public.choose.app/website/2023/Stars@2x.png 2x,https://public.choose.app/website/2023/Stars@3x.png 3x`}
              />
              <p>{t("rating")}</p>
            </div>
            <p className={styles.subtitle}>{t("rate")}</p>
          </div>
          <div className={`${styles.more} col-sm-0`}>
            <LazyProductImage
              height="28"
              width="24"
              alt="ArrowDown"
              src="https://public.choose.app/website/new/images/arrow.svg"
            />
            <p>{t("more")}</p>
          </div>
        </div>
        <div className="col-1 col-sm-0 col-md-0" />
        <div className={`${styles.iphone} col-4 col-sm-12 col-md-5`}>
          <div>
            <LazyProductVideo
              // poster="https://public.choose.app/website/new/images/Rectangle3.png"
              className={styles.video}
              src={
                i18n.language === "en"
                  ? `https://public.choose.app/website/new/video/ChooseHomeVideo-US.mp4`
                  : `https://public.choose.app/website/new/video/ChooseHomeVideo_v4.mp4`
              }
            />
            <LazyProductImage
              containerClassName={styles.big}
              height="916"
              width="459"
              style={{ marginLeft: "auto", marginRight: "auto" }}
              alt="phone background"
              src="https://public.choose.app/website/new/images/Iphone.png"
              srcSet={`https://public.choose.app/website/new/images/Iphone@2x.png 2x,https://public.choose.app/website/new/images/Iphone@3x.png 3x`}
            />
            <LazyProductImage
              containerClassName={styles.small}
              height="610"
              width="305"
              style={{ marginLeft: "auto", marginRight: "auto" }}
              alt="phone background"
              src="https://public.choose.app/website/new/images/img-small-iphone.png"
              srcSet={`https://public.choose.app/website/new/images/img-small-iphone@2x.png 2x,https://public.choose.app/website/new/images/img-small-iphone@3x.png 3x`}
            />
          </div>
        </div>
        <div className={`${styles.bg} col-4 col-sm-0 col-md-4`} />
      </div>
    </div>
  );
}

export default PhoneNewComponent;
